import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "zipCodesTextArea"]

  open() {
    this.modalTarget.showModal()
  }

  close() {
    this.modalTarget.querySelector("#search-zip-codes-results").innerHTML = ""
    this.modalTarget.close()
  }

  acceptResults() {
    let zipCodesText = ""
    this.modalTarget.querySelectorAll("table td:first-child").forEach((td) => {
      zipCodesText += td.innerText + "\n"
    })
    this.zipCodesTextAreaTarget.value = zipCodesText
    this.close()
  }
}
